import axios from '@/http'

export interface paginationSetting {
  page: number
  pageSize: number
  api: string
  payload?: any
}

const usePagination = async (paginationSetting: paginationSetting) => {
  let currentPage = paginationSetting.page
  let lastPage = 1
  const pageSize = paginationSetting.pageSize
  let total = 0
  let items: never[] = []
  let param = Object.assign(
    { page: paginationSetting.page },
    { pageSize: paginationSetting.pageSize },
    paginationSetting.payload
  )
  await axios
    .post(paginationSetting.api, param)
    .then((res) => {
      const data = res.data
      currentPage = data.currentPage
      lastPage = data.lastPage
      total = data.total
      items = data.items
    })
    .catch((err) => {
      console.log(err.response.errors.detail)
    })

  const fetchData = () => {
    return new Promise((resolve, reject) => {
      param = Object.assign(
        { page: currentPage },
        { pageSize: pageSize },
        paginationSetting.payload
      )
      axios
        .post(paginationSetting.api, param)
        .then((res) => {
          const data = res.data
          currentPage = data.currentPage
          lastPage = data.lastPage
          total = data.total
          items = data.items
          resolve(data)
        })
        .catch((err) => {
          reject(err.response.errors.detail)
        })
    })
  }

  const next = () => {
    if (lastPage > currentPage) {
      currentPage += 1
      return fetchData()
    }
    return new Promise((resolve, reject) => reject)
  }

  const pre = () => {
    if (currentPage - 1) {
      currentPage -= 1
      return fetchData()
    }
    return new Promise((resolve, reject) => reject)
  }

  const go = (goPage: number) => {
    currentPage = Number(goPage)
    return fetchData()
  }

  const getData = () => {
    return {
      currentPage,
      lastPage,
      pageSize,
      total,
      items
    }
  }

  return { next, pre, go, getData }
}

export default usePagination
