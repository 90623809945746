
import { computed, defineComponent, inject, reactive, ref, toRef } from 'vue'
import DateRangePicker from '@/views/components/dateRangePicker.vue'
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment'
import usePagination from '@/plugins/usePostPagination'
import exportExcel from '@/plugins/exportExcel'
import { modalInstance, notifyInstance } from '@/type'
import { useStore } from 'vuex'
import helper from '@/helper'

export default defineComponent({
  components: {
    DateRangePicker
  },
  setup() {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance
    const route = useRoute()

    const store = useStore()
    const router = useRouter()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)
    const exportType = ref('PartialpickupRecord')

    let pagination: {
      next: () => any
      pre: () => any
      go: (goPage: number) => any
      getData: () => any
    } | null = null

    const paginationData = reactive({
      page: 1,
      pageSize: 10,
      lastPage: 1,
      total: 0,
      records: ref(new Array<any>())
    })

    const range = reactive({
      start: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
      end: moment().endOf('day').format('YYYY-MM-DDTHH:mm')
    })

    const paginationSetting = reactive({
      page: toRef(paginationData, 'page'),
      pageSize: toRef(paginationData, 'pageSize'),
      api: '/partialpickup/recordStatistics',
      payload: {
        startTime: toRef(range, 'start'),
        endTime: toRef(range, 'end')
      }
    })

    function updateRange(updateRange: any) {
      range.start = moment(updateRange.start).format('YYYY-MM-DDTHH:mm')
      range.end = moment(updateRange.end).format('YYYY-MM-DDTHH:mm')
      goPage(1)
    }

    const initPagination = usePagination(paginationSetting).then((res) => {
      pagination = res
    })

    function nextPage() {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData()
      })
    }

    function prePage() {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData()
      })
    }

    function goPage(page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData()
      })
    }

    function getData() {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.records = data.items
    }

    function getProductStandardName(product: any) {
      if (product.standardNameMain.length > 0) {
        if (product.standardNameSub.length > 0) {
          return `(${product.standardNameMain}-${product.standardNameSub})`
        }
        return `(${product.standardNameMain})`
      }
      return ''
    }

    function exportExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            startTime: moment(range.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(range.end).format('YYYY-MM-DDTHH:mm')
          },
          modal,
          notify,
          router
        )
      }
    }

    Promise.all([initPagination]).then(function () {
      getData()
    })

    return {
      route,
      range,
      updateRange,
      paginationData,
      getProductStandardName,
      prePage,
      nextPage,
      goPage,
      exportExcelClick
    }
  }
})
