
import { defineComponent, inject, reactive, ref, toRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import usePagination from '@/plugins/usePostPagination'
import moment from 'moment'
import axios from '@/http'
import { notifyInstance } from '@/type'

moment.locale('zh-tw')

interface pagination {
  getData: () => void
  go: (page: number) => void
  next: () => void
  pre: () => void
}

export default defineComponent({
  components: {},
  setup() {
    const notify = inject('notify') as notifyInstance
    const route = useRoute()

    let pagination: {
      next: () => any
      pre: () => any
      go: (goPage: number) => any
      getData: () => any
    } | null = null

    const paginationData = reactive({
      page: 1,
      pageSize: 10,
      lastPage: 1,
      total: 0,
      products: ref(new Array<any>())
    })

    const statistics = reactive({
      unclaimed: 0,
      claimed: 0
    })

    const paginationSetting = reactive({
      page: toRef(paginationData, 'page'),
      pageSize: toRef(paginationData, 'pageSize'),
      api: '/partialpickup/products'
    })

    const initPagination = usePagination(paginationSetting).then((res) => {
      pagination = res
    })

    Promise.all([initPagination]).then(function () {
      fetchStatistics()
      getData()
    })

    function fetchStatistics() {
      axios
        .post('/partialpickup/statistics')
        .then((res) => {
          const data = res.data.statistics
          statistics.unclaimed = Number(data.unclaimed)
          statistics.claimed = Number(data.claimed)
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function getProductStandardName(product: any) {
      if (product.standardNameMain.length > 0) {
        if (product.standardNameSub.length > 0) {
          return `(${product.standardNameMain}-${product.standardNameSub})`
        }
        return `(${product.standardNameMain})`
      }
      return ''
    }

    function formatNumber(number: any) {
      return number === undefined ? 0 : parseInt(number).toLocaleString('en-US')
    }

    function nextPage() {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData()
      })
    }

    function prePage() {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData()
      })
    }

    function goPage(page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData()
      })
    }

    function getData() {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.products = data.items
    }

    return {
      route,
      formatNumber,
      paginationData,
      prePage,
      nextPage,
      goPage,
      statistics,
      getProductStandardName
    }
  }
})
